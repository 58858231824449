<template>
    <div :class="{'meta-bar': true, 'dev-background': devRelease, 'disabled': disabled}">
        <div class="meta-bar__container container">
            <div class="meta-bar__left">
                <AtomMetaBarPlatformSwitch v-if="platforms" :platforms="platforms" />

                <div v-if="navigations && navigations.data">
                    <template v-for="(navigation, index2) in navigations.data.attributes.nodes">
                        <AtomMetaBarButton
                            v-if="index2 === 2"
                            :key="index2"
                            :text="navigation.title"
                            :icon="navigation.cssClass"
                            @click="navigate(navigation.url)"
                        />
                    </template>
                </div>
            </div>
            <div v-if="navigations && navigations.data" class="meta-bar__right">
                <template v-for="(navigation, index) in navigations.data.attributes.nodes">
                    <AtomMetaBarButton
                        v-if="index !== 2"
                        :key="index"
                        :text="navigation.title"
                        :icon="navigation.cssClass"
                        @click="navigate(navigation.url)"
                    />
                </template>
            </div>
            <div
                v-if="($can(PermissionTypes.ORDER) || $hasRole(RoleTypes.MEMBER)) && activeCart"
                v-role:not="RoleTypes.SUPPLIER"
                class="active-cart-container"
                :title="activeCart.name"
                @click="navigate(`${NuxtLinkNameTypes.CART_PAGE}/${activeCart.id}`)"
            >
                <AtomIcon name="basket" :width="20"/>
                <span class="active-label">{{ $t('General.active') }}</span>
                <span>{{ activeCart.name }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { EnvironmentTypes } from '~/composables/types/environmentTypes';
import type { AdditionalOrderFieldGroup } from '~/composables/types/api/searchDiscover';
import { PermissionTypes } from '~/composables/types/permissionTypes';
import { NuxtLinkNameTypes } from '~/composables/types/nuxtHyperlinkTypes';
import { RoleTypes } from '~/composables/types/roleTypes';

const { $emitter, $hasRole } = useNuxtApp();
const { loadNavigation, navigations } = useNavigation({ id: 'META_HEADER_NAVIGATION_PRE_ORDER' });
const platformsStore = usePlatformsStore();
const cartsStore = useCartsStore();
const { getCachedData } = useLocalStorageCache<AdditionalOrderFieldGroup[]>(LocalStorageNameTypes.ORDER_FIELDS_KEY);
const { loadAdditionalOrderFieldsData } = useAdditionalOrderFields();
const { platforms } = storeToRefs(platformsStore);
const { activeCart } = storeToRefs(cartsStore);
const config = useRuntimeConfig();
const devRelease = config.public.releaseStage !== EnvironmentTypes.prod;
const disabled = ref(false);
const { $can } = useNuxtApp();

const navigate = (link: string) => {
    navigateTo(link);
};

const onMatrixModalOpened = () => {
    disabled.value = true;
};

const onMatrixModalClosed = () => {
    disabled.value = false;
};

onMounted(async() => {
    await loadNavigation();
    await platformsStore.getPlatforms();

    if (!$hasRole(RoleTypes.SUPPLIER) && !activeCart.value) {
        await cartsStore.loadCarts();
    }

    if (!getCachedData()) {
        await loadAdditionalOrderFieldsData();
    }

    $emitter.$on('matrixModal.opened', onMatrixModalOpened);
    $emitter.$on('matrixModal.closed', onMatrixModalClosed);
});

onBeforeUnmount(() => {
    $emitter.$off('matrixModal.opened', onMatrixModalOpened);
    $emitter.$off('matrixModal.closed', onMatrixModalClosed);
});
</script>

<style lang="scss" scoped>
.meta-bar {
    position: sticky;
    top: 0;
    left: 0;
    height: sp(l);
    width: 100%;
    z-index: $setting-zi-header-metabar;
    display: flex;
    justify-content: center;

    @include helper-color-bg(light-gray);

    @include helper-breakpoint-media-between($sm, $lg) {
        display: none;
    }

    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__left {
        overflow: visible;
        display: flex;
        align-items: center;

        .meta-bar-button {
            margin-right: sp(l);
        }
    }

    &__right {
        overflow: visible;
        display: flex;
        align-items: center;

        .meta-bar-button {
            margin-left: sp(l);
        }
    }

    &__spacer {
        width: rem(1);
        height: rem(20);
        background-color: $setting-color-gray-5;
        opacity: 0.25;
        margin-left: sp(l);
    }

    .active-cart-container {
        cursor: pointer;
        display: flex;
        align-items: center;

        .svg-icon {
            margin-right: sp(xxs);

            @include helper-color(text-title);
        }

        .active-label {
            font-size: fs(smaller);
            font-weight: fw(medium);
            padding: 0 rem(8);
            line-height: lh(regular);
            margin: 0 rem(4);

            @include helper-color-bg(alert-success);
            @include helper-color(white);
            @include helper-border-radius-circle();
        }

        > span:last-child {
            font-weight: fw(regular);

            @include helper-color(text-primary);
        }

        &:hover {
            > span:last-child {
                text-decoration: underline;
            }
        }
    }

    &.disabled {
        pointer-events: none;
    }
}

.right-wrapper {
    display: inherit;
}

.dev-background {
    @include helper-color-bg(alert-warning);

    background-image: linear-gradient(-45deg, rgba(#fff, 0.2) 25%, 25%, transparent 50%, 50%, rgba(#fff, 0.2) 75%, 75%, transparent);
    background-size: rem(50) rem(50);
    box-shadow: inset 0 0 rem(10) rgba(#fff, 0.3), inset 0 0 rem(5) rgb(0 0 0 / 40%);
}
</style>
