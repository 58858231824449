

export type PlatformAttributes = {
    link: string;
    name: string;
}

export type PlatformSore = {
    attributes: PlatformAttributes;
}

interface IPlatformsState {
    platforms: PlatformSore[],
    isLoading: boolean,
}

interface IPlatformsActions {
    getPlatforms: () => void,
}

export default defineStore<string, IPlatformsState, any, IPlatformsActions>('platforms', {
    state: () => ({
        platforms: [],
        isLoading: false,
    }),
    actions: {
        async getPlatforms() {
            if (this.platforms.length || this.isLoading) {
                return;
            }

            const { loadPlatforms, platforms } = usePlatforms();

            this.isLoading = true;
            await loadPlatforms();
            this.platforms = platforms.value as any[];
            this.isLoading = false;
        }
    },
});
