import type {
    CatalogSearchResponsePrice,
    CatalogSearchSuggestionsProduct,
    CatalogSearchSuggestionsResult
} from '~/composables/types/api/searchDiscover/catalogSearchSuggestions';
import handleError from '~/composables/api/handleResponse';
import type { ApiResponse } from '~/composables/types/api/apiResponse';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

const prepareAbstractProducts = (abstractProducts: any[]): CatalogSearchSuggestionsProduct[] => abstractProducts.map((abstractProduct) => ({
    ...abstractProduct,
    purchasePriceIntersportType: abstractProduct?.priceDataByPriceType?.DEFAULT?.intersportPriceType ?? null,
    purchasePrice: abstractProduct.prices.find((price: CatalogSearchResponsePrice) => price.priceTypeName === 'DEFAULT'),
    sellPrice: abstractProduct.prices.find((price: CatalogSearchResponsePrice) => price.priceTypeName === 'PURCHASE'),
}));

export default async function(query: string, options?: any): Promise<CatalogSearchSuggestionsResult | ApiResponse> {
    const include = 'label';
    const url = `/catalog-search-suggestions?q=${query}&include=${include}`;
    const { fetchSdApi } = fetchOrderDetermination();

    try {
        const response = await fetchSdApi(url, options) as any;

        if (response.error) {
            return handleError<ApiResponse>(response);
        }

        return {
            abstractProducts: prepareAbstractProducts(response.data[0].attributes.abstractProducts),
            completion: response.data[0].attributes.completion || [],
        } as CatalogSearchSuggestionsResult;
    } catch (error) {
        return handleError<ApiResponse>(options?.signal?.reason || error);
    }
}
